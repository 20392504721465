import React from 'react';
import { THead, TRow } from '../styles/index.styled';
import TableCell from './TableCell';

const TableHead = ({ columns }) => {
  if (!columns) return null;

  return (
    <thead>
      <TRow numOfColumns={columns?.length} className="table-row">
        {columns.map(
          ({ id, variant, heading, subhead, iconType, alignLeft }) => (
            <THead
              className={alignLeft ? 'align-left' : ''}
              key={id}
              aria-label={heading}
            >
              <TableCell
                heading={heading}
                iconType={iconType}
                subhead={subhead}
                variant={variant}
              />
            </THead>
          )
        )}
      </TRow>
    </thead>
  );
};

export default TableHead;
