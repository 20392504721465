import React from 'react';
import TableBody from './components/TableBody';
import TableHead from './components/TableHead';
import { Container, StyledTable } from './styles/index.styled';

const Table = ({ tableData }) => {
  if (!tableData || tableData?.legnth === 0) return null;

  return (
    <Container>
      <StyledTable>
        {tableData.map(({ id, rowType, columnCell }) => {
          if (rowType === 'table-heading') {
            return <TableHead key={id} columns={columnCell} />;
          }
          if (rowType === 'table-cell') {
            return <TableBody key={id} columns={columnCell} />;
          }
        })}
      </StyledTable>
    </Container>
  );
};

export default Table;
